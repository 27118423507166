<template>
    <vx-card :title="getTitle">
        <div class="mb-10 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>

        <div class="flex md:flex-row mb-6">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Shipment Doc</span>
                    </div>
                    <div class="w-full vx-col sm:w-1/3">
                        <vs-input :value="this.form.pod_code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Customer Data</span>
                    </div>
                    <div class="w-full vx-col sm:w-1/3">
                        <vs-input :value="this.form.cus_name" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>SO Data</span>
                    </div>
                    <div class="w-full vx-col sm:w-1/3">
                        <vs-input :value="this.form.so_code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>DO Data</span>
                    </div>
                    <div class="w-full vx-col sm:w-1/3">
                        <vs-input :value="this.form.do_code" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>POD Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-1/3">
                        <vs-input :value="formatDate(this.form.pod_date)" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>POD Status</span>
                    </div>
                    <div class="w-full vx-col sm:w-1/3">
                        <vs-input :value="this.form.pod_status" disabled :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div v-if="!this.is_receive" class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Loading Area</span>
                    </div>
                    <div class="w-full vx-col sm:w-1/3">
                        <vs-input v-model="form.loading_area" :disabled="(this.form.pod_status != 'Failed Redeliver') ? true : false" :class="(this.form.pod_status != 'Failed Redeliver') ? 'bg-grey-light' : ''" class="w-full"></vs-input>
                    </div>
                </div>
            </div>            
        </div>

        <div class="row mb-2">
            <h4>Returned Item</h4>
            <hr>
        </div>

        <div class="mb-6 vx-row">
            <div class="w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th colspan="4" class="text-center" style="padding-right: 2%;">
                                <h5>From POD</h5>
                                <hr>
                            </th>
                            <th colspan="6" class="text-center" style="padding-left: 2%;">
                                <h5>Received</h5>
                                <hr>
                            </th>
                        </tr>
                        <tr>
                            <th width="7%">SKU Code</th>
                            <th>SKU Description</th>
                            <th width="5%">HU</th>
                            <th width="8%">Qty</th>

                            <th width="7%" style="padding-left: 2%;">HU</th>
                            <th width="7%">Qty</th>
                            <th width="7%">Batch</th>
                            <th width="8%">ED</th>
                            <th width="17%">Checked Status</th>
                            <th v-if="!isReceive" width="17%">Warehouse Area</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in form.data_line" :key="index">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.item_code" :readonly="true" disabled class="w-full bg-grey-light" v-show="!item.dupe"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.item_name" :readonly="true" disabled class="w-full bg-grey-light" v-show="!item.dupe"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.item_unit" :readonly="true" disabled class="w-full bg-grey-light" v-show="!item.dupe"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px; padding-right: 2%;">
                                <vs-input :value="item.quantity" :readonly="true" disabled class="w-full bg-grey-light" v-show="!item.dupe"></vs-input>
                            </td>

                            <td class="td vs-table--td" style="padding: 5px; padding-left: 2%;">
                                <vs-input class="w-full" :class="true ? 'bg-grey-light' : ''" v-model="item.e_item_unit" :disabled="true"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" :class="isDisabled ? 'bg-grey-light' : ''" type="number" v-model="item.e_quantity" :disabled="isDisabled"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" :class="true ? 'bg-grey-light' : ''" v-model="item.batch" :disabled="true"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <datepicker name="date" :inline="false"
                                    v-model="item.ed" 
                                    :disabled="true"
                                    placeholder="Select Date"
                                    :cleared="() => {
                                        item.ed = null
                                    }">
                                </datepicker>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px;">
                                <CustomMSelect :value="item.status"
                                    :disabled="isDisabled"
                                    :options="checkOptions" label="name" track-by="name"                                    
                                    @selected="(v) => {
                                        item.status = v
                                        checkType(index, v)
                                        item.reDraw += 1
                                    }">
                                </CustomMSelect>
                            </td>
                            <td v-if="!isReceive && !isDisabled" class="td vs-table--td"  style="padding: 5px; padding-right: 1%;">
                                <WarehouseArea :disabled="isDisabled"
                                    :base-url="`/api/wms/v1/master/warehouse-areas/status`"
                                    :warehouse-id="warehouseId"
                                    :key="item.reDraw"
                                    :warehouse-area-type="item.wh_area_type"
                                    :damaged="item.status.value"
                                    label="name" track-by="name"
                                    :value="item.optionsWh"
                                    @selected="(v) => {onSelect(index, v)}">
                                </WarehouseArea>
                            </td>
                            <td class="td vs-table--td" style="text-align: center">
                                <div v-if="checkTab != 'Complete'" class="flex vx-input-group">
                                    <vs-button v-if="item.dupe" @click.stop="removeItemsRow(index)" size="small" color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px" title="Remove Row" />
                                    <vs-button v-else :disabled="item.has_child" v-show="!isDisabled" @click.stop="addItemsRow(index)" size="small" color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mb-6 pt-6 vx-row">
            <div class="w-full vx-col sm:w-1/1">
                <div v-if="checkTab != 'Complete'" class="float-right">
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(false)">Save</vs-button>
                    <vs-button color="success" class="mb-2 mr-3" @click="handleSubmit(true)">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import WarehouseArea from "./component/WarehouseArea.vue";

export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
        WarehouseArea,
    },    
    mounted() {
        this.id = this.$route.params.id;
        this.tab = this.$route.params.tab;
        this.is_receive = (this.$route.params.tab != "Receive") ? false : true;        
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            tab : null,
            title: "Form POD Return ",
            dialog: {
                save: false,
            },
            id: null,
            is_edit: false,
            is_receive: true,
            form: {
                id: null,
                pod_id: null,
                pod_code: null,
                pod_status: null,
                status: null,
                pod_date: null,
                so_id: null,
                so_code: null,
                do_id: null,
                do_code: null,
                wh_id: null,
                cus_id: null,
                cus_code: null,
                cus_name: null,
                loading_area: null,
                is_confirm: null,
                tab: null,
                data_line: [],
            },
            checkOptions: [{
                value: 0,
                name: "Passed",
            }, {
                value: 1,
                name: "Damage",
            }, {
                value: 2,
                name: "Expired",
            }],
        }
    },
    methods: {
        checkType(i, v){
            if(v){
                if (v.name == "Passed"){
                    this.form.data_line[i].wh_area_type = "Storage"
                }else{
                    this.form.data_line[i].wh_area_type = "Special"
                }            
            }else{
                this.form.data_line[i].wh_area_type = "kosong"
            }
            this.form.data_line[i].optionsWh = null
        },
        onSelect (i, v) {
            this.form.data_line[i].wh_area = v.id
            this.form.data_line[i].optionsWh = v
        },
        addItemsRow(index) {
            var remain_qty = this.form.data_line[index].quantity - this.form.data_line[index].e_quantity
            const newItem = Object.assign({}, this.form.data_line[index])
            newItem.dupe = true
            newItem.e_quantity = remain_qty
            newItem.status = {}
            newItem.optionsWh = null
            this.form.data_line.splice(index + 1, 0, newItem);
            if (this.form.data_line[index+2].dupe) {
                this.form.data_line[index].has_child = true
            }
        },
        removeItemsRow(index) {
            let parentIndex = 0;
            if(this.form.data_line[index - 1].dupe){
                parentIndex = index - 2
            }else{
                parentIndex = index - 1
            }
            this.form.data_line.splice(index, 1);
            this.form.data_line[parentIndex].has_child = false;            
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.podReturn.baseRouterName,                
            });
            this.$vs.loading.close();
        },        
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.podReturn.baseUrlPath}/edit-pod/${this.id}`)
                .then((resp) => {                    
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        var data_head = resp.data.data_head[0]
                        this.form.pod_id = parseInt(this.id)
                        this.form.pod_code = (data_head && data_head.pod_code !== undefined) ? data_head.pod_code : '';
                        this.form.pod_date = data_head.pod_date
                        this.form.do_id = data_head.do_id
                        this.form.do_code = data_head.do_code
                        this.form.so_id = data_head.so_id
                        this.form.so_code = data_head.so_code
                        this.form.cus_id = data_head.cus_id
                        this.form.cus_code = data_head.cus_code
                        this.form.cus_name = data_head.cus_name
                        this.form.wh_id = data_head.wh_id                        
                        var pod_status = ""
                        if (data_head.status_delivery == 2) {
                            pod_status = 'Partial Complete'
                        } else if (data_head.status_delivery == 4) {
                            pod_status = 'Failed Complete'
                        } else {
                            pod_status = 'Failed Redeliver'
                        }                        
                        this.form.pod_status = pod_status
                        
                        if(resp.data.data_edit.length > 0){
                            this.is_edit = true
                            var temp_item = ""
                            var temp_idx = null
                            resp.data.data_edit.forEach((el, idx) => {
                                this.form.id = parseInt(el.pod_return_id)
                                var pod_line_id = 0
                                var item_name = ""
                                var item_unit = ""
                                var item_unit_id = 0
                                var item_qty = 0
                                var status = {}
                                var wh_area_type = "kosong"
                                var batch = ""
                                var exp_date = 0

                                resp.data.data_line.forEach(ed => {
                                    if(ed.pod_line_id == el.pod_line_id){
                                        pod_line_id = ed.pod_line_id
                                        item_name = ed.item_name
                                        item_unit = ed.item_unit
                                        item_unit_id = ed.item_unit_id
                                        item_qty = ed.return_quantity
                                        batch = ed.batch
                                        exp_date = ed.expired_date
                                    }
                                })

                                if(el.quality_check == 'Passed'){
                                    status = {'value': 0, 'name': 'Passed'}
                                    wh_area_type = 'Storage'
                                }else if (el.quality_check == 'Damage'){
                                    status = {'value': 1, 'name': 'Damage'}
                                    wh_area_type = 'Special'
                                }else if (el.quality_check == 'Expired'){
                                    status = {'value': 2, 'name': 'Expired'}
                                    wh_area_type = 'Special'
                                }
                                const newItem = {
                                    pod_line_id: pod_line_id,
                                    item_code: el.item_code,
                                    item_name: item_name,
                                    item_unit_id: item_unit_id,
                                    item_unit: el.item_unit,
                                    quantity: item_qty,
                                    e_item_unit: el.item_unit,
                                    e_quantity: el.quantity,
                                    batch: batch,
                                    ed: exp_date,
                                    status: status,
                                    wh_area_type: wh_area_type,
                                    reDraw: 0,
                                    q_check: el.quality_check,
                                    wh_area: el.warehouse_area_id,
                                    optionsWh: {
                                        id: el.warehouse_area_id,
                                        name: el.wh_name
                                    },
                                    has_child: false,
                                    dupe: (temp_item == el.item_code+'-'+el.item_unit+'-'+el.quantity+'-'+batch) ? true : false,
                                    lastChild: null,
                                    parentIdx: null,
                                    countChild: 0
                                }
                                this.form.data_line.push(newItem)
                                if(temp_item == el.item_code+'-'+el.item_unit+'-'+el.quantity+'-'+batch) {
                                    if(temp_idx != null){
                                        var lastChild = this.form.data_line[temp_idx].lastChild
                                        var tempIndex = lastChild == null ? temp_idx + 1 : lastChild + 1
                                        this.form.data_line[idx].parentIdx = temp_idx
                                        this.form.data_line[temp_idx].lastChild = tempIndex
                                        this.form.data_line[temp_idx].countChild++
                                        if (this.form.data_line[temp_idx].countChild == 2){
                                            this.form.data_line[temp_idx].has_child = true
                                        }                                        
                                    }
                                }else{
                                    temp_idx = idx
                                }
                                temp_item = el.item_code+'-'+el.item_unit+'-'+el.quantity+'-'+batch
                            })
                        }else{
                            resp.data.data_line.forEach(el => {
                                console.log(el)
                                const newItem = {
                                    pod_line_id: el.pod_line_id,
                                    item_code: el.item_code,
                                    item_name: el.item_name,
                                    item_unit_id: el.item_unit_id,
                                    item_unit: el.item_unit,
                                    quantity: el.return_quantity,
                                    e_item_unit: el.item_unit,
                                    e_quantity: el.return_quantity,
                                    batch: el.batch,
                                    ed: el.expired_date,
                                    status: null,
                                    wh_area_type: "kosong",
                                    reDraw: 0,
                                    q_check: null,
                                    wh_area: null,
                                    has_child: false,
                                    lastChild: null,
                                    parentIdx: null,
                                    countChild: 0
                                }
                                this.form.data_line.push(newItem)
                            })
                            console.log(this.form.data_line)
                        }                                                
                    }                    
                });
        },
        handleSubmit(stsConfirm) {
            var proceed = true;            
            try{
                const groupedByItemCode = this.form.data_line.reduce((accumulator, current) => {
                    const itemCode = current.item_code+'-'+current.item_unit_id+'-'+current.quantity+'-'+current.batch;
                    accumulator[itemCode] = accumulator[itemCode] || { sum: 0, qty: 0 };
                    accumulator[itemCode].sum += parseInt(current.e_quantity);
                    accumulator[itemCode].qty = current.quantity;
                    accumulator[itemCode].code = current.item_code;
                    accumulator[itemCode].unit = current.item_unit;
                    accumulator[itemCode].batch = current.batch;
                    return accumulator;
                }, {});

                console.log(groupedByItemCode)
                for (const itemCode in groupedByItemCode) {
                    console.log(itemCode)
                    const { sum, qty, code, unit, batch } = groupedByItemCode[itemCode];
                    if (qty !== sum) {
                        const message = `quantity of item from POD (${code} / ${unit} / ${batch}) must be ${qty}`;
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: message,
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');                            
                    }else{
                        proceed = true
                    }
                }
                
                this.form.data_line.forEach((el, idx) => {
                    if(!this.isDisabled){
                        if(this.tab == "PutAway"){                            
                            if (this.form.data_line[idx].wh_area == null || this.form.data_line[idx].wh_area == 0) {
                                this.$vs.dialog({
                                    type: 'alert',
                                    color: 'danger',
                                    title: 'Information',
                                    text: 'Warehouse Area is required!',
                                    acceptText: 'OK',
                                });
                                proceed = false
                                throw new Error('BreakException');
                            }
                        }
                        
                        if (this.form.data_line[idx].ed == null) {
                            this.$vs.dialog({
                                type: 'alert',
                                color: 'danger',
                                title: 'Information',
                                text: 'ED is required!',
                                acceptText: 'OK',
                            });
                            proceed = false
                            throw new Error('BreakException');
                        }else if (this.form.data_line[idx].batch == null || this.form.data_line[idx].batch == "") {
                            this.$vs.dialog({
                                type: 'alert',
                                color: 'danger',
                                title: 'Information',
                                text: 'Batch is required!',
                                acceptText: 'OK',
                            });
                            proceed = false
                            throw new Error('BreakException');
                        }else if (this.form.data_line[idx].status == null) {
                            this.$vs.dialog({
                                type: 'alert',
                                color: 'danger',
                                title: 'Information',
                                text: 'Checked Status is required!',
                                acceptText: 'OK',
                            });
                            proceed = false
                            throw new Error('BreakException');
                        }else {                            
                            proceed = true
                        }
                    }else{
                        if(this.form.loading_area == null || this.form.loading_area == "") {
                            this.$vs.dialog({
                                type: 'alert',
                                color: 'danger',
                                title: 'Information',
                                text: 'Loading Area is required!',
                                acceptText: 'OK',
                            });
                            proceed = false
                            throw new Error('BreakException');
                        }else{
                            proceed = true
                        }
                    }
                });                                    
            }catch (e) {
                if (e.message !== 'BreakException') throw e;
            }            
            if(proceed){
                if(stsConfirm){
                    this.$vs.dialog({
                        type: "confirm",
                        color: "primary",
                        title: "Confirmation",
                        text: "Are you sure Confirm this data",
                        acceptText: "Yes, sure",
                        accept: () => this.acceptConfirm(stsConfirm),
                    });
                }else{
                    this.acceptConfirm(stsConfirm)
                }
            }
        },
        acceptConfirm(stsConfirm){            
            this.form.pod_date = moment(this.form.pod_date).format('YYYY-MM-DD')
            this.form.is_confirm = stsConfirm
            this.form.tab = this.tab
            this.form.data_line.forEach((el, idx) => {
                this.form.data_line[idx].ed = moment(this.form.data_line[idx].ed).format('YYYY-MM-DD')
                this.form.data_line[idx].q_check = (this.form.data_line[idx].status) ? this.form.data_line[idx].status.name : ''
                this.form.data_line[idx].e_quantity = parseInt(this.form.data_line[idx].e_quantity)
            })
            let formData = new FormData();            
            formData.append("data", JSON.stringify(this.form))
            formData.append("lines", JSON.stringify(this.form.data_line))

            this.$vs.loading()
            let http = (!this.is_edit) ? this.$http.post(this.$store.state.inbound.podReturn.baseUrlPath, formData) : this.$http.put(this.$store.state.inbound.podReturn.baseUrlPath, formData)
            http.then((resp) => {
                console.log(resp)
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });

                    this.$vs.loading.close();
                    this.draw++;
                    this.handleBack()
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Failed",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            })
        }
    },
    computed:{
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                if (momentDate.isValid()) {
                    return momentDate.format('DD MMM YYYY');
                } else {
                    return ""; // Return empty string for invalid date
                }
            };
        },
        isDisabled() {
            return this.form.pod_status === 'Failed Redeliver';
        },
        isReceive() {
            return this.is_receive;
        },
        warehouseId() {
            return this.form.wh_id;
        },
        checkTab(){
            return this.tab;
        },
        getTitle(){
            return this.tab != 'Complete' ? this.title + this.tab : 'Detail POD Return';
        }
    }
}
</script>